import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1c838a2c = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _6a25f9fc = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _7d97fcbd = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _b91854e6 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _5677efb6 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _8fd985c4 = () => interopDefault(import('../pages/realtime.vue' /* webpackChunkName: "pages/realtime" */))
const _fc01c044 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _26879a5c = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _d5161de2 = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "pages/topic/index" */))
const _a7a88f24 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _a872ed4a = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _bdc2b86e = () => interopDefault(import('../pages/brands/meridank.vue' /* webpackChunkName: "pages/brands/meridank" */))
const _7f0155d0 = () => interopDefault(import('../pages/brands/sinyi.vue' /* webpackChunkName: "pages/brands/sinyi" */))
const _45d9f9f3 = () => interopDefault(import('../pages/amp/article/_id.vue' /* webpackChunkName: "pages/amp/article/_id" */))
const _c9dfd284 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _4898e6d4 = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _27fe6c7c = () => interopDefault(import('../pages/series-sort/_id.vue' /* webpackChunkName: "pages/series-sort/_id" */))
const _1459eb37 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _2ae5b2fa = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _f3fe35c2 = () => interopDefault(import('../pages/author/_role/_name.vue' /* webpackChunkName: "pages/author/_role/_name" */))
const _83940cb4 = () => interopDefault(import('../pages/category/_mainCategory/_subCategory.vue' /* webpackChunkName: "pages/category/_mainCategory/_subCategory" */))
const _117615af = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1c838a2c,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/brands",
    component: _6a25f9fc,
    meta: {},
    alias: ["/amp/brands"],
    name: "brands"
  }, {
    path: "/contact",
    component: _7d97fcbd,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/member",
    component: _b91854e6,
    meta: {},
    alias: ["/amp/member"],
    name: "member"
  }, {
    path: "/privacy",
    component: _5677efb6,
    meta: {},
    alias: ["/amp/privacy"],
    name: "privacy"
  }, {
    path: "/category/%E6%9C%80%E6%96%B0",
    component: _8fd985c4,
    meta: {},
    alias: ["/amp/category/%E6%9C%80%E6%96%B0"],
    name: "realtime"
  }, {
    path: "/search",
    component: _fc01c044,
    meta: {},
    alias: ["/amp/search"],
    name: "search"
  }, {
    path: "/sitemap",
    component: _26879a5c,
    meta: {},
    alias: ["/amp/sitemap"],
    name: "sitemap"
  }, {
    path: "/topic",
    component: _d5161de2,
    meta: {},
    alias: ["/amp/topic"],
    name: "topic"
  }, {
    path: "/unsubscribe",
    component: _a7a88f24,
    meta: {},
    alias: ["/amp/unsubscribe"],
    name: "unsubscribe"
  }, {
    path: "/video",
    component: _a872ed4a,
    meta: {},
    alias: ["/amp/video"],
    name: "video"
  }, {
    path: "/brands/meridank",
    component: _bdc2b86e,
    meta: {},
    alias: ["/amp/brands/meridank"],
    name: "brands-meridank"
  }, {
    path: "/brands/sinyi",
    component: _7f0155d0,
    meta: {},
    alias: ["/amp/brands/sinyi"],
    name: "brands-sinyi"
  }, {
    path: "/amp/article/:id?",
    component: _45d9f9f3,
    meta: {"amp":true},
    alias: [],
    name: "amp-article-id"
  }, {
    path: "/article/:id?",
    component: _c9dfd284,
    meta: {},
    alias: ["/amp/article/:id?"],
    name: "article-id"
  }, {
    path: "/search/:q",
    component: _4898e6d4,
    meta: {},
    alias: ["/amp/search/:q"],
    name: "search-q"
  }, {
    path: "/series-sort/:id?",
    component: _27fe6c7c,
    meta: {},
    alias: ["/amp/series-sort/:id?"],
    name: "series-sort-id"
  }, {
    path: "/topic/:id",
    component: _1459eb37,
    meta: {},
    alias: ["/amp/topic/:id"],
    name: "topic-id"
  }, {
    path: "/video/:id",
    component: _2ae5b2fa,
    meta: {},
    alias: ["/amp/video/:id"],
    name: "video-id"
  }, {
    path: "/author/:role?/:name?",
    component: _f3fe35c2,
    meta: {},
    alias: ["/amp/author/:role?/:name?"],
    name: "author-role-name"
  }, {
    path: "/category/:mainCategory?/:subCategory?",
    component: _83940cb4,
    meta: {},
    alias: ["/amp/category/:mainCategory?/:subCategory?"],
    name: "category-mainCategory-subCategory"
  }, {
    path: "/",
    component: _117615af,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
