// server middleware 生命週期是比vue渲染還早，在這先取得使用者裝置，可以判斷之後server side render 時的v-if dom是否要被渲染
// 注意! 在這執行階段為後端，所以取得不到瀏覽器資訊的
export default function ({ req, store }) {
  // console.log('middleware device: ', app)
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
  // console.log('device.js userAgent: ', userAgent)
  store.commit('SET_IS_MOBILE', /mobile/i.test(userAgent))
  // eslint-disable-next-line no-useless-escape
  // if (new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})').exec(userAgent) != null) {
  //   console.log('isIE: ', parseFloat(RegExp.$1))
  //   store.commit('SET_IS_IE', true)
  // }
}
