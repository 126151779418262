<template>
  <transition name="fade">
    <div v-show="isOpenDownloadApp" class="m-downloadapppopup" @click="closePopup">
      <div class="m-downloadapppopup__content" @click.stop="() => {}">
        <div class="app-icon">
          <img src="https://static.ctwant.com/images/dist/app_icon_180x180.jpg" alt="CTWANT App icon" width="180" height="180">
        </div>
        <span>安裝我們的 CTWANT APP 體驗更好的服務，接收更快的即時資訊。</span>
        <div class="app-qrcodes">
          <div>
            <client-only>
              <qrcode :value="iosInstallUrl" />
            </client-only>
            <span>iPhone</span>
            <div>
              <img src="https://static.ctwant.com/images/dist/apple-logo-white.png" alt="ios icon" width="20" height="20">
              <a :href="iosInstallUrl" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 App Store 的 CTWANT 下載頁面">立即安裝</a>
            </div>
          </div>
          <div>
            <client-only>
              <qrcode :value="androidInstallUrl" />
            </client-only>
            <span>Android</span>
            <div>
              <img src="https://static.ctwant.com/images/dist/android-logo-white.png" alt="android icon" width="20" height="20">
              <a :href="androidInstallUrl" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 Google Play 的 CTWANT 下載頁面">立即安裝</a>
            </div>
          </div>
        </div>
        <div class="app-close">
          <button type="button" title="關閉下載 App 視窗" aria-label="關閉下載 App 視窗" @click="closePopup" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'DownloadAppPopup',
  data() {
    return {
      iosInstallUrl: 'https://apps.apple.com/tw/app/ctwant-%E5%91%A8%E5%88%8A%E7%8E%8B/id1580357215',
      androidInstallUrl: 'https://play.google.com/store/apps/details?id=com.ctwant.ctwantapp'
    }
  },
  computed: {
    ...mapGetters([
      'isOpenDownloadApp'
    ])
  },
  methods: {
    ...mapMutations([
      'SET_IS_OPEN_DOWNLOAD_APP'
    ]),
    closePopup() {
      this.SET_IS_OPEN_DOWNLOAD_APP(false)
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/modules/_downloadapppopup';
</style>
