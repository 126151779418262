<template>
  <footer class="l-footer">
    <div class="accesskey-bottom container">
      <a id="accesskey-b" accesskey="B" href="#accesskey-b" title="下方頁尾版權宣告及相關分頁">:::</a>
    </div>
    <div class="l-footer__content">
      <img src="https://static.ctwant.com/images/dist/logo-w.svg" class="l-footer__logo" width="128" height="23" alt="CTWANT 識別標誌">
      <ul class="l-footer__list">
        <li>
          <nuxt-link to="/about/" class="l-footer__item" title="前往關於 CTWANT 頁面">
            關於CTWANT
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/contact/" class="l-footer__item" title="前往聯繫&爆料頁面">
            聯繫&amp;爆料
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/privacy/" class="l-footer__item" title="前往隱私權政策頁面">
            隱私權政策
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/brands/" class="l-footer__item" title="前往發燒熱搜頁面">
            <img src="https://static.ctwant.com/images/dist/fire.svg" width="16" height="21" alt="發燒熱搜強調圖示">
            發燒熱搜
          </nuxt-link>
        </li>
      </ul>
    </div>
    <ul class="l-footer__social">
      <li>
        <a href="https://www.facebook.com/want.ctw/" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 CTWANT 的 Facebook 粉絲頁">
          <img src="https://static.ctwant.com/images/dist/footer-fb.svg" width="19" height="19" class="l-footer__icon" alt="Facebook icon">
          Facebook
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCZtUbkty-OfR4_AQ4B0GtQA" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 CTWANT 的 Youtube 頻道頁">
          <img src="https://static.ctwant.com/images/dist/footer-youtube.svg" width="24" height="19" class="l-footer__icon" alt="Youtube icon">
          Youtube
        </a>
      </li>
      <li>
        <a href="https://t.me/ctwant" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener nofollow" title="外網連結 - 另開視窗連結至 CTWANT 的 Telegram 加入頁面">
          <img src="https://static.ctwant.com/images/dist/Telegram.svg" width="19" height="19" class="l-footer__icon" alt="Telegram icon">
          Telegram
        </a>
      </li>
    </ul>
    <hr class="l-footer__hr">
    <div class="l-footer-copyright">
      <span class="l-footer-copyright__title">
        © 2020 The CTWANT Company
      </span>
      本網站所刊載內容著作權屬王道旺台媒體股份有限公司所有或經授權使用，他人非經授權不許轉載、重製、公開播送或公開傳輸。
    </div>
    <div class="l-ad__wrapper l-ad--fixed l-ad--show-pad">
      <div id="div-gpt-ad-1566899685984-0" ref="div-gpt-ad-1566899685984-0" style="min-width: 320px; min-height: 50px;" />
    </div>
    <AdPopup />
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import AdPopup from '@/components/AdPopup.vue'
import adTabIndexRemove from '@/utils/adTabIndexRemove.js'

export default {
  components: {
    AdPopup
  },
  mixins: [adTabIndexRemove],
  data() {
    return {
      adHide: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isMobile === false) {
        this.$_adTabIndexRemoveFn([
          this.$refs['div-gpt-ad-1566899685984-0']
        ])
      }
    })
  }
}
</script>
