export default {
  data() {
    return {
      $_adTabIndexRemoveTimer: null,
      $_adTabIndexRemoveFromTagATimer: null
      // $_AD_TAB_INDEX_REMOVE_TIME: 800
    }
  },
  // mounted() {
  //   console.log('@@@@@ adTabIndexRemove.js @@@@@')
  // },
  beforeDestroy() {
    if (this.$_adTabIndexRemoveTimer !== null) {
      clearTimeout(this.$_adTabIndexRemoveTimer)
    }
    if (this.$_adTabIndexRemoveFromTagATimer !== null) {
      clearTimeout(this.$_adTabIndexRemoveFromTagATimer)
    }
  },
  methods: {
    $_adTabIndexRemoveFn(adElements) {
      this.$_adTabIndexRemoveTimer = setTimeout(() => {
        adElements.forEach((adDom) => {
          // console.log('adDom: ', adDom)
          if (adDom && adDom.style.display !== 'none') {
            const adIframe = adDom.querySelector('iframe')
            // console.log('adIframe: ', adIframe)
            if (adIframe) {
              adIframe.setAttribute('tabindex', '-1')
              // console.log('adIframe: ', adIframe)
            }
          }
        })
      }, 800)
    },
    $_adTabIndexRemoveFromTagA(adElements) {
      this.$_adTabIndexRemoveFromTagATimer = setTimeout(() => {
        adElements.forEach((adDom) => {
          // console.log('adDom: ', adDom)
          if (adDom) {
            const adATags = adDom.querySelectorAll('a')
            // console.log('adATags: ', adATags)
            if (adATags.length > 0) {
              adATags.forEach((aTag) => {
                aTag.setAttribute('tabindex', '-1')
                // console.log('aTag: ', aTag)
              })
            }
          }
        })
      }, 1500)
    }
  }
}
