import { Notyf } from 'notyf'

export default function ({ $axios, redirect }) {
  $axios.setHeader('Content-Type', 'application/json')
  $axios.onError((error) => {
    const { data } = error.response

    if (process.client) {
      const notyf = new Notyf({
        position: {
          x: 'right',
          y: 'top'
        }
      })
      notyf.error(`${data.code} ${data.message}`)

      if (Object.prototype.hasOwnProperty.call(data, 'errors')) {
        for (const key in data.errors.validation) {
          notyf.error(`${data.errors.validation[key]}`)
        }
      }
    }
  })
}
