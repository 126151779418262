<template>
  <footer class="l-footer">
    <div class="l-footer__content">
      <amp-img
        src="https://static.ctwant.com/images/dist/logo-w.svg"
        width="128"
        height="23"
        class="m-amp-footer__logo"
        layout="responsive"
        alt="footer logo"
      />
      <ul class="l-footer__list">
        <li>
          <a href="https://www.ctwant.com/about/" class="l-footer__item" title="前往關於 CTWANT 頁">
            關於CTWANT
          </a>
        </li>
        <li>
          <a href="https://www.ctwant.com/contact/" class="l-footer__item" title="前往 CTWANT 聯繫與爆料頁">
            聯繫&amp;爆料
          </a>
        </li>
        <li>
          <a href="https://www.ctwant.com/privacy/" class="l-footer__item" title="前往 CTWANT 隱私權政策">
            隱私權政策
          </a>
        </li>
        <li>
          <a href="https://www.ctwant.com/brands/" class="l-footer__item" title="前往 CTWANT 發燒熱搜頁">
            <amp-img
              width="16"
              height="21"
              src="https://static.ctwant.com/images/dist/fire.svg"
              alt="發燒熱搜強調圖示"
            />
            發燒熱搜
          </a>
        </li>
      </ul>
    </div>
    <ul class="l-footer__social">
      <li>
        <a href="https://www.facebook.com/want.ctw" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 CTWANT 的 Facebook 粉絲頁">
          <amp-img
            src="https://static.ctwant.com/images/dist/footer-fb.svg"
            width="19"
            height="19"
            class="m-amp-footer__icon"
            layout="responsive"
            alt="Facebook icon"
          />
          Facebook
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCZtUbkty-OfR4_AQ4B0GtQA" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至 CTWANT 的 Youtube 頻道">
          <amp-img
            src="https://static.ctwant.com/images/dist/footer-youtube.svg"
            width="23.75"
            height="19"
            class="m-amp-footer__icon-youtube"
            layout="responsive"
            alt="Youtube icon"
          />
          Youtube
        </a>
      </li>
      <li>
        <a href="https://t.me/ctwant" class="l-footer__icon-wrapper l-footer__item" target="_blank" rel="noreferrer noopener nofollow" title="外網連結 - 另開視窗連結至 CTWANT 的 Telegram 加入頁">
          <amp-img
            src="https://static.ctwant.com/images/dist/Telegram.svg"
            width="19"
            height="19"
            class="m-amp-footer__icon"
            layout="responsive"
            alt="Telegram icon"
          />
          Telegram
        </a>
      </li>
    </ul>
    <hr class="l-footer__hr">
    <div class="l-footer-copyright">
      <h4 class="l-footer-copyright__title">
        © 2020 The CTWANT Company
      </h4>
      本網站所刊載內容著作權屬王道旺台媒體股份有限公司所有或經授權使用，他人非經授權不許轉載、重製、公開播送或公開傳輸。
    </div>
    <amp-sticky-ad layout="nodisplay">
      <amp-ad
        :json="targeting"
        width="320"
        height="50"
        type="doubleclick"
        data-slot="/21830015939/ctwant_amp_article_anchor"
      />
    </amp-sticky-ad>
  </footer>
</template>

<script>
export default {
  computed: {
    targeting() {
      if (this.$store.state.ad.isOtherAd === 'Y') {
        return '{"targeting":{"adult":"Y"}}'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// modules
@import '~/assets/scss/modules/_amp';
// layout
@import '~/assets/scss/layout/_footer';
</style>
