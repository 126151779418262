<template>
  <img
    :data-src="src"
    :alt="alt"
    src="https://static.ctwant.com/images/dist/imageholder.jpg"
    class="lazyload"
    onerror="this.src='https://static.ctwant.com/images/dist/default_cover_md.png'"
  >
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
      default: 'https://static.ctwant.com/images/dist/default_cover_md.png'
    },
    alt: {
      type: String,
      default: 'alt'
    }
  }
}
</script>
