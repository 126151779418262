<template>
  <div v-if="isShow" class="m-notification">
    <h3 class="m-notification__title">
      歡迎訂閱
      <img src="https://static.ctwant.com/images/dist/logo.svg" alt="CTWANT 識別標誌">
      新聞推播
    </h3>
    <p class="m-notification__content">
      請點選「訂閱」後，再點擊「允許」即可完成網頁新聞推播訂閱！
    </p>
    <div class="m-notification__footer">
      <button class="m-notification__btn" title="不訂閱 CTWANT 網頁新聞推播" @click="isShow = false">
        暫時不用
      </button>
      <button class="m-notification__btn" title="訂閱 CTWANT 網頁新聞推播" @click="popup">
        訂閱
      </button>
    </div>
  </div>
</template>

<script>
/* global firebase */

export default {
  name: 'Webpush',
  data() {
    return {
      apiBaseURL: this.$axios.defaults.baseURL,
      cookieKey: 'fcm_token',
      cookieToken: null,
      cookiePopup: true,
      timeOut: null,
      isShow: false
    }
  },
  mounted() {
    if (firebase.messaging.isSupported()) {
      this.cookieToken = document.cookie.replace(/(?:(?:^|.*;\s*)fcm_token\s*=\s*([^;]*).*$)|^.*$/, '$1') || null
      this.cookiePopup = document.cookie.replace(/(?:(?:^|.*;\s*)fcm_popup\s*=\s*([^;]*).*$)|^.*$/, '$1') || true
      this.registerServiceWorker()

      this.timeOut = setTimeout(() => {
        if (this.cookiePopup === true) {
          if (Notification.permission === 'denied' || Notification.permission === 'undefined') {
            this.isShow = false
            this.deleteToken(this.cookieToken)
          } else if (Notification.permission === 'granted') {
            this.isShow = false
            this.getToken()
          } else {
            this.setCookie('fcm_popup', false, 1)
            this.isShow = true
          }
        }
      }, 5000)

      firebase.messaging().onTokenRefresh(() => {
        this.getToken()
      })
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  },
  methods: {
    async registerServiceWorker() { // 註冊 service worker
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
          firebase.messaging().useServiceWorker(registration)
        })
      }
    },
    getToken() { // 取得 Token
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          firebase.messaging().getToken().then((currentToken) => {
            if (currentToken) {
              this.postToken(currentToken)
            }
          }).catch((err) => {
            console.error('getToken', err)
          })
        } else {
          this.setCookie('fcm_popup', false, 0)
        }
      })
    },
    async postToken(currentToken) {
      try {
        if (this.cookieToken !== currentToken) {
          this.deleteToken(this.cookieToken)
          const url = `${this.apiBaseURL}/webpush/user`
          await this.$axios.post(url, {
            website: 'ctwant',
            token: currentToken,
            is_prod: this.$config.mode === 'production' ? 'Y' : 'N'
          })
        }
        this.cookieToken = currentToken
        this.setCookie(this.cookieKey, currentToken, 1)
      } catch (e) {
        console.error('Webpush postToken', {
          code: e.response.data.code,
          message: e.response.data.message,
          method: e.response.config.method,
          url: e.response.config.url
        })
      }
    },
    async deleteToken(currentToken) {
      try {
        if (currentToken !== null && currentToken !== '') {
          const url = `${this.apiBaseURL}/webpush/user/${currentToken}`
          await this.$axios.delete(url)
        }
        this.cookieToken = null
        this.setCookie(this.cookieKey, '', 0)
      } catch (e) {
        console.error('Webpush deleteToken', {
          code: e.response.data.code,
          message: e.response.data.message,
          method: e.response.config.method,
          url: e.response.config.url
        })
      }
    },
    popup() { // webpush 點擊訂閱
      this.isShow = false
      this.getToken()
    },
    setCookie(name, value, expiredays) {
      const exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = `${name}=${value}; expires=` + exdate.toGMTString()
    }
  }
}
</script>
<style lang="scss">
  @import '~/assets/scss/modules/_webpush';
</style>
