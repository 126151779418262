<template>
  <div
    :class="{
      'l-default--default-font-family': isSetFontFamilyDefault,
      'l-ad--hide-all-units': isHideAd === 'Y',
    }"
  >
    <a href="#accesskey-c" class="skip-to-content" title="跳至主要內容區塊" tabindex="1">跳至主要內容區塊</a>
    <Header id="accesskey-u" />
    <DownloadAppPopup />
    <main>
      <div class="accesskey-center container">
        <a id="accesskey-c" accesskey="C" href="#accesskey-c" title="主要內容區塊">:::</a>
      </div>
      <nuxt />
    </main>
    <GDPR />
    <Webpush />
    <!-- <Gotop /> -->
    <!-- 自動刷新廣吿 -->
    <div id="div-gpt-ad-1610439124541-0" />
    <!-- 離站廣告 -->
    <div id="compass-fit-uaid" />
    <Stream />
    <Footer id="accesskey-b" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Cookies from 'js-cookie'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GDPR from '@/components/GDPR.vue'
import Webpush from '@/components/Webpush.vue'
import Stream from '@/components/Stream.vue'
// import Gotop from '@/components/Gotop.vue'
import DownloadAppPopup from '@/components/DownloadAppPopup.vue'

export default {
  components: {
    Header,
    Footer,
    GDPR,
    Webpush,
    // Gotop,
    Stream,
    DownloadAppPopup
  },
  data() {
    return {
      isSetFontFamilyDefault: false
    }
  },
  computed: {
    ...mapGetters([
      'WIDTH_LAPTOP',
      'WIDTH_MOBILE',
      'screenWidth',
      'isOpenDownloadApp'
    ]),
    isHideAd() {
      return this.$store.state.ad.isHideAd
    }
  },
  watch: {
    isOpenDownloadApp(value) {
      if (value === true) {
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.documentElement.style.overflow = 'auto'
      }
    }
  },
  created() {
    // if (process.client) {
    //   this.checkLogin()
    // }
  },
  beforeMount() {
    this.SET_SCREEN_WIDTH(window.screen.width)
    window.addEventListener('scroll', this.scrollTopHandler)
    this.$store.dispatch('ad/registerGlobalSlots')
    this.$store.dispatch('ad/registerAndDisplayAutoRefreshSlot')
  },
  mounted() {
    this.SET_IS_LAPTOP(this.screenWidth <= this.WIDTH_LAPTOP)
    this.SET_IS_MOBILE(this.screenWidth <= this.WIDTH_MOBILE)

    // 如果在 iPhone 的 Google (search) app 就使用預設字型
    if (/iPhone/.test(navigator.userAgent) && /GSA/.test(navigator.userAgent)) {
      this.isSetFontFamilyDefault = true
    }
  },
  methods: {
    ...mapMutations([
      'SET_SCREEN_WIDTH',
      'SET_SCROLL_TOP',
      'SET_IS_LAPTOP',
      'SET_IS_MOBILE'
    ]),
    scrollTopHandler() {
      this.SET_SCROLL_TOP(this.scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop)
    },
    removeUrlIdToken() {
      const query = Object.assign({}, this.$route.query)
      delete query.id_token
      this.$router.replace({ query })
    },
    async checkLogin() {
      if (this.$route.query.id_token && this.$route.query.id_token !== null) {
        // 透過 url 登入
        await this.$store.dispatch('member/login', this.$route.query.id_token)
        this.removeUrlIdToken()
      } else if (Cookies.get('idToken')) {
        const idToken = Cookies.get('idToken')
        // 透過 cookie 登入
        this.$store.dispatch('member/checkIdToken', idToken)
        this.$store.dispatch('member/login', idToken)
      } else {
        this.$store.commit('member/SET_IS_LOGIN', false)
      }
    }
  }
}
</script>

<style lang="scss">
  @import 'notyf/notyf.min.css';
  @import '../assets/scss/main.scss';

.skip-to-content{
  position: fixed!important;
  font-size: 1.2rem;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 0.375rem;

  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
  color: $primary;

  z-index: 100001;
  &:focus{
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 1.7rem 2rem 1.2rem;
    border: 1px solid #dedede;
  }
}
.accesskey-center{
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 4;
  @include pad{
    display: none;
  }
  #accesskey-c{
    color: $bg-gdpr;
  }
}
</style>
