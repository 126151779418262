<template>
  <div v-show="!isHide" class="m-adpopup">
    <div class="m-adpopup__content">
      <button
        ref="adPopupCloseBtnRef"
        class="e-btn e-btn-ad-close e-btn-ad-close--popup"
        title="關閉廣告"
        @click="closeAdPopup"
      >
        <img src="https://static.ctwant.com/images/dist/xx.svg" alt="關閉廣告視窗圖示">
        <span class="e-btn-ad-close__preventReClicks" />
      </button>
      <div id="ad-popup-1582014334406-0" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isHide: true,
      bodyHeight: 0,
      isRunPopupAdScrollFn: false,
      watchSignupForScrollTop: null
    }
  },
  computed: {
    ...mapGetters([
      'WIDTH_MOBILE',
      'screenWidth',
      'scrollTop'
    ]),
    ...mapGetters('ad', [
      'isPopupActive'
    ])
  },
  mounted() {
    // 手機上才顯示
    if (this.screenWidth <= this.WIDTH_MOBILE) {
      this.bodyHeight = document.body.offsetHeight
      this.isRunPopupAdScrollFn = true

      // 手動註冊 watch, 因為 Popup ad 一但被User關閉，該 watch 就不必執行
      this.watchSignupForScrollTop = this.$watch('scrollTop', (newVal, prevVal) => {
        if (!this.isRunPopupAdScrollFn) {
          return false
        }
        if (!this.isPopupActive) {
          this.closeAdPopup()
          return
        }
        if (this.scrollTop >= this.bodyHeight * 0.3) {
          this.isHide = false

          // 如果廣告太大幅造成關閉鈕被推擠到是窗外，需要將按鈕拉回來
          const rect = this.$refs.adPopupCloseBtnRef.getBoundingClientRect()
          if (!(rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          )) {
            this.$refs.adPopupCloseBtnRef.style.top = '-30px'
          }
        }
      })
    }
  },
  methods: {
    closeAdPopup() {
      this.isHide = true
      this.isRunPopupAdScrollFn = false
      this.watchSignupForScrollTop() // 註銷手動註冊的 watch
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/modules/_adpopup';
@import '~/assets/scss/layout/_ad';
</style>
