<template>
  <div v-if="show" class="m-GDPR">
    <div class="m-GDPR__container">
      <p class="m-GDPR__content">
        為提供最佳服務，本站使用cookies，您可以點選
        <nuxt-link to="/privacy/" title="前往隱私權政策頁">
          隱私權政策
        </nuxt-link>
        查看，若繼續瀏覽網頁，即表示同意我們的cookies政策。
      </p>
      <div class="m-GDPR__btnBox">
        <button class="m-GDPR__btn" title="已了解隱私權政策並關閉視窗" @click="acceptCookies">
          <div class="m-GDPR__close-icon">
            <img src="https://static.ctwant.com/images/dist/xx-black.svg" alt="關閉隱私權政策視窗圖示">
          </div>
          知道了，請關閉視窗
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GDPR',
  data() {
    return {
      show: false
    }
  },
  mounted() {
    const userData = JSON.parse(window.localStorage.getItem('acceptCookies'))
    if (userData === null) {
      this.show = true
    }
  },
  methods: {
    acceptCookies() {
      this.show = false
      localStorage.setItem('acceptCookies', true)
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/modules/_GDPR';
</style>
