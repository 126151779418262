import Cookies from 'js-cookie'

export const state = () => ({
  isLogin: null,
  isGetUserData: false,
  idToken: null,
  userData: {
    address: '',
    article_read_count: 0,
    avatar: '',
    birth: '',
    city: '',
    district: '',
    email: '',
    gender: '',
    last_login_at: '',
    name: '',
    phone: '',
    type: ''
  }
})

export const actions = {
  async login({ dispatch, commit }, idToken) {
    const config = {
      headers: {
        'Firebase-idToken': idToken
      }
    }
    try {
      const data = await this.$axios.$get(`${this.$config.baseURL}/users`, config)
      dispatch('checkIdToken', data.id_token)
      commit('SET_IS_LOGIN', true)
      commit('SET_USER_DATA', data.data)
      commit('SET_IS_GET_USER', true)
    } catch (e) {
      if (process.client) {
        Cookies.remove('idToken')
      }
      console.error('Login', {
        code: e.response.data.code,
        message: e.response.data.message,
        method: e.response.config.method,
        url: e.response.config.url
      })
    }
  },
  logout() {
    Cookies.remove('idToken')
  },
  async sendArticleReadCount({ state, dispatch }, articleId) {
    try {
      const config = {
        headers: {
          'Firebase-idToken': state.idToken
        }
      }
      const { data } = await this.$axios.$post(`${this.$config.baseURL}/users/read_record/article/${articleId}`, null, config)
      dispatch('checkIdToken', data.id_token)
    } catch (e) {
      console.error('sendArticleReadCount', {
        code: e.response.data.code,
        message: e.response.data.message,
        method: e.response.config.method,
        url: e.response.config.url
      })
    }
  },
  getArticleReadCount({ state, dispatch, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            'Firebase-idToken': state.idToken
          }
        }
        const { data } = await this.$axios.$get(`${this.$config.baseURL}/users`, config)
        dispatch('checkIdToken', data.id_token)
        commit('SET_USER_DATA', data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  sendUserData({ dispatch, commit }, { token, ...userData }) {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            'Firebase-idToken': token
          }
        }
        const { data } = await this.$axios.$put(`${this.$config.baseURL}/users`, userData, config)
        dispatch('checkIdToken', data.id_token)
        commit('SET_USER_DATA', data)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  },
  checkIdToken({ commit }, idToken) {
    if (!idToken) {
      return
    }
    commit('SET_ID_TOKEN', idToken)
    if (idToken !== Cookies.get('idToken', idToken)) {
      Cookies.set('idToken', idToken)
    }
  }
}

export const mutations = {
  SET_IS_LOGIN(state, status) {
    state.isLogin = status
  },
  SET_IS_GET_USER(state, status) {
    state.isGetUserData = status
  },
  SET_ID_TOKEN(state, idToken) {
    state.idToken = idToken
  },
  SET_USER_DATA(state, data) {
    if (data !== null) {
      state.userData = Object.assign({}, data)
    } else {
      state.userData = data
    }
  }
}
