export default {
  methods: {
    ReplaceNewline(data) {
      return data.replace(/\n/g, '<br />')
    },
    articleType(data) {
      const contentType = data.content_type ? data.content_type : data.type
      const typeData = {
        style: {},
        text: ''
      }

      switch (contentType) {
        case 'release':
          typeData.style['e-type--red'] = true
          typeData.text = '情報'
          break
        case 'sponsored':
          typeData.style['e-type--green'] = true
          typeData.text = '特輯'
          break
      }

      return typeData
    }
    /*
    // Tag 使用 router 轉跳
    goTagHref(tagKeyword) {
      const keyword = tagKeyword.trim().length > 20 ? tagKeyword.trim().substr(0, 20) : tagKeyword.trim()
      this.$router.push(`/search?q=${keyword}`)
    },
    // Article&Video 作者連結 使用 router 轉跳
    goAuthorHref(authorVal) {
      this.$router.push(`/author${authorVal.split('/author')[1]}`)
    }
    */
  }
}
