<template>
  <header class="l-header">
    <div class="l-header__content">
      <div class="l-header__head">
        <a href="https://www.ctwant.com" title="前往 CTWANT 首頁">
          <amp-img
            src="https://static.ctwant.com/images/dist/logo.svg"
            width="99"
            height="18"
            class="m-amp-header__logo"
            layout="responsive"
            alt="CTWANT 識別標誌"
          />
        </a>
        <a href="https://www.ctwant.com/search/" title="前往搜尋頁">
          <amp-img
            src="https://static.ctwant.com/images/dist/search.svg"
            width="18"
            height="18"
            class="m-amp-header__search l-header__search"
            layout="responsive"
            alt="搜尋符號"
          />
        </a>
      </div>
      <nav class="m-amp-navbar l-header__nav">
        <ul class="container m-amp-navbar__list">
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/最新/" class="m-amp-navbar__list__link" title="前往 CTWANT 最新新聞頁">
              最新
            </a>
            <div class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/社會/" class="m-amp-navbar__list__link" title="前往 CTWANT 社會分類頁">
              社會
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/娛樂/" class="m-amp-navbar__list__link" title="前往 CTWANT 娛樂分類頁">
              娛樂
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/財經/" class="m-amp-navbar__list__link" title="前往 CTWANT 財經分類頁">
              財經
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/政治/" class="m-amp-navbar__list__link" title="前往 CTWANT 政治分類頁">
              政治
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/漂亮/" class="m-amp-navbar__list__link" title="前往 CTWANT 漂亮分類頁">
              漂亮
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/生活/" class="m-amp-navbar__list__link" title="前往 CTWANT 生活分類頁">
              生活
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/category/國際/" class="m-amp-navbar__list__link" title="前往 CTWANT 國際分類頁">
              國際
            </a>
            <div :style="arrowPos" class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/video/" class="m-amp-navbar__list__link" title="前往 CTWANT 影音頁">
              影音
            </a>
            <div class="m-amp-navbar__arrow" />
          </li>
          <li class="m-amp-navbar__list__item">
            <a href="https://www.ctwant.com/topic/" class="m-amp-navbar__list__link" title="前往 CTWANT 專題頁">
              專題
            </a>
            <div class="m-amp-navbar__arrow" />
          </li>
        </ul>
        <div class="m-amp-navbar__control">
          <svg width="8px" height="15px" viewBox="0 0 8 15" version="1.1">
            <title>往後查看類別項目</title>
            <g id="首頁" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="2019/6/20_首頁_mobile(375)" transform="translate(-344.000000, -189.000000)" fill="#ffffff" fill-rule="nonzero">
                <g id="Group-9" transform="translate(0.000000, 144.000000)">
                  <g id="arrow-point-to-right" transform="translate(344.000000, 45.000000)">
                    <path id="Path" d="M7.71217089,8.24257713 L1.67769864,14.6921386 C1.29383331,15.1026205 0.671461489,15.1026205 0.287782521,14.6921386 C-0.0959275069,14.2820219 -0.0959275069,13.6168521 0.287782521,13.2067686 L5.62735883,7.49989211 L0.287937819,1.79321485 C-0.0957722091,1.38293217 -0.0957722091,0.717828712 0.287937819,0.307712013 C0.671647847,-0.102570671 1.29398861,-0.102570671 1.67785394,0.307712013 L7.71232619,6.75737307 C7.9041812,6.96253101 8,7.23112857 8,7.49985891 C8,7.76872205 7.90399484,8.03751878 7.71217089,8.24257713 Z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      subnavPos: {
        top: '44px',
        left: 'auto'
      },
      arrowPos: {
        left: 'auto'
      },
      headerFixed: false,
      headerShow: false
    }
  }
}
</script>

<style lang="scss" scoped>
// modules
@import '~/assets/scss/modules/_amp';
// layout
@import '~/assets/scss/layout/_header';
</style>
