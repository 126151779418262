export const state = () => ({
  host: 'https://www.ctwant.com',
  MEMBER_HOST: 'https://member.ctwant.com',
  redirectUrl: '',
  user: null,
  WIDTH_LAPTOP: 992,
  WIDTH_PAD: 768,
  WIDTH_MOBILE: 576,
  screenWidth: 0,
  scrollTop: 0,
  isPlayingStream: false,
  isLaptop: true,
  isMobile: true,
  isOpenDownloadApp: false
})

export const actions = {
  /**
   * @param {string} $route.path
   */
  getRedirectUrl({ state, commit }, path) {
    commit('SET_REDIRECT_URL', `${state.memberUrl}/login?redirect=${location.origin}${path}`)
  }
}

export const mutations = {
  SET_REDIRECT_URL(state, url) {
    state.redirectUrl = url
  },
  SET_SCREEN_WIDTH(state, widthNun) {
    state.screenWidth = widthNun
  },
  SET_SCROLL_TOP(state, scrollTopNum) {
    state.scrollTop = scrollTopNum
  },
  SET_IS_PLAYING_STREAM(state, bool) {
    state.isPlayingStream = bool
  },
  SET_IS_LAPTOP(state, bool) {
    state.isLaptop = bool
  },
  SET_IS_MOBILE(state, bool) {
    state.isMobile = bool
  },
  SET_IS_OPEN_DOWNLOAD_APP(state, bool) {
    state.isOpenDownloadApp = bool
  }
}

export const getters = {
  HOST: state => state.host,
  MEMBER_HOST: state => state.MEMBER_HOST,
  WIDTH_LAPTOP: state => state.WIDTH_LAPTOP,
  WIDTH_PAD: state => state.WIDTH_PAD,
  WIDTH_MOBILE: state => state.WIDTH_MOBILE,
  screenWidth: state => state.screenWidth, // 請勿直接在 defaults/pages/components 的<template>使用，因為是在default.layout的beforeMounted就給值，這時候html還沒渲染上去。如需在<template>可參考article的pageScreenWidth
  scrollTop: state => state.scrollTop, // 〃
  isPlayingStream: state => state.isPlayingStream,
  isLaptop: state => state.isLaptop,
  isMobile: state => state.isMobile,
  isOpenDownloadApp: state => state.isOpenDownloadApp
}
