import Vue from 'vue'
export default ({ app, store }) => {
  // 無障礙網頁 tab 點擊後到下一頁，必須重置 tab
  app.router.afterEach((to, from) => {
    // console.log('routingEach afterEach from: ', from)
    // console.log('routingEach afterEach to: ', to)
    if (from.path !== to.path) {
      Vue.nextTick(() => {
        document.body.setAttribute('tabindex', '-1')
        document.body.focus()
        document.body.removeAttribute('tabindex')
      })
    }
  })

  // 在搜尋結果頁，users 點擊 article or video 需要打一支 api 讓後端去紀錄
  app.router.beforeEach((to, from, next) => {
    if (from.name === 'search-q' && (to.name === 'article-id' || to.name === 'video-id')) {
      app.$axios.$post(`${app.$config.baseURL}/search`, {
        keyword: from.params.q,
        type: to.name === 'article-id' ? 'article' : 'video',
        id: to.params.id
      })
      next()
    } else {
      next()
    }
  })

  /*
  // 插頁式廣告邏輯
  app.router.beforeEach((to, from, next) => {
    // 主要觸發於 article 至 article
    if (from.name === 'article-id' && to.name === 'article-id') {
      // 查看到第三篇文章才會觸發插頁廣告
      store.commit('ad/SET_ARTICLE_PREV_OR_NEXT_COUNT', store.getters['ad/articlePrevOrNextCount'] + 1)
      const articlePrevOrNextCount = store.getters['ad/articlePrevOrNextCount']
      // console.log('app.router.beforeEach articlePrevOrNextCount: ', articlePrevOrNextCount)

      if (articlePrevOrNextCount !== 0 && articlePrevOrNextCount % 3 === 0) {
        // 插頁廣告根據 Google 規定是每位 User一小時只會觸發一次。插頁廣告觸發後，會產生 Storage 的 __lsv__: [timestamp] 要判斷是否有該值存在及是否現在時間已經超過一小時
        const lsv = localStorage.getItem('__lsv__')
        const lsvReplace = lsv ? Math.floor(lsv.replace(/[^\d]/g, ' ') * 1) || null : null
        // console.log('lsvReplace+1hour: ', lsvReplace ? lsvReplace + 3600000 : null) // 3600000 為 timestamp 的一小時
        if (lsvReplace === null || (lsvReplace + 3600000) <= new Date().getTime()) {
          store.commit('ad/SET_INTERSTITIAL_CLOSE_NEXT_TO', to.path) // 儲存 to article 的路徑
          store.dispatch('ad/displayInterstitialAd') // 觸發顯示插頁式廣告的 ad邏輯
        } else {
          next()
        }
      } else {
        next()
      }
    } else if (to.name === 'article-id') {
      store.commit('ad/SET_ARTICLE_PREV_OR_NEXT_COUNT', store.getters['ad/articlePrevOrNextCount'] + 1)
      next()
    } else { // 如果不是 to article, 將查看到第三篇這紀錄歸0, 避免值已經是2 下一次看文章時直接被插頁廣告
      store.commit('ad/SET_ARTICLE_PREV_OR_NEXT_COUNT', 0)
      next()
    }
  })
  */
}
