<template>
  <header ref="header" :class="{ 'l-header--fixed': headerFixed, 'l-header--show': headerShow }" class="l-header">
    <div class="l-ad__wrapper pt-2 l-ad__all-top">
      <div id="div-gpt-ad-1563334121951-0" ref="div-gpt-ad-1563334121951-0" />
    </div>
    <div class="l-header__content">
      <div class="l-header__head">
        <div class="l-header__logo-container">
          <nuxt-link to="/" title="前往至首頁">
            <template
              v-if="$route.path.includes('/article/') ||
                $route.path.includes('/video/') ||
                $route.path.includes('/topic/') ||
                $route.path.includes('/search/') ||
                $route.path.includes('/author/') ||
                $route.path.includes('/category/') "
            >
              <img src="https://static.ctwant.com/images/dist/logo.svg" class="l-header__img l-header__logo" width="143" height="26" alt="CTWANT 識別標誌">
            </template>
            <template v-else>
              <h1><img src="https://static.ctwant.com/images/dist/logo.svg" class="l-header__img l-header__logo" width="143" height="26" alt="CTWANT 識別標誌"></h1>
            </template>
          </nuxt-link>
          <!-- 好康吧 Logo. 該 id 在 GTM 可控 Dom, 請參考 GTM: "Shop Logo 更新連結及 Icon" -->
          <!--
          <a id="shop-Logo" href="https://shop.ctwant.com/?utm_source=ctwant&utm_medium=ctwantlogo&utm_campaign=好康吧" class="l-header__second-logo" target="_blank" rel="noreferrer noopener" title="外網連結 - 另開視窗連結至好康吧">
            <img src="https://static.ctwant.com/images/dist/shop_ctwant_sm.gif" width="300" height="96" alt="shop_ctwant_logo">
          </a>
          -->
          <!-- <a href="https://www.ctwant.com/topic/72/" class="l-header__second-logo" title="前往2024大選開票頁面">
            <img src="https://static.ctwant.com/images/dist/election_2024_v5.gif" width="300" height="96" alt="election_2024_icon">
          </a> -->
        </div>
        <div class="l-header__group">
          <!-- <nuxt-link id="topic-logo" to="/topic/">
            <img src="https://static.ctwant.com/images/dist/topicsEnterBtn.svg" width="92" height="53" alt="topics enter">
          </nuxt-link> -->
          <!-- 下載 APP -->
          <!-- <div v-if="!isMobile" :style="{ display: isMobile ? 'none' : 'block'}" class="l-header__sitemap">
            <nuxt-link class="l-header__sitemap-btn" to="/sitemap/" title="前往網站導覽頁">
              網站導覽
            </nuxt-link>
          </div> -->
          <div class="l-header__downloadapp">
            <button type="button" class="l-header__downloadapp-btn" title="開啟下載 CTWANT App 視窗" tabindex="-1" @click="downloadApp">
              下載 APP
            </button>
          </div>
          <nuxt-link to="/search/" title="前往搜尋頁">
            <img src="https://static.ctwant.com/images/dist/search.svg" width="24" height="24" class="l-header__img l-header__search" alt="搜尋符號">
          </nuxt-link>
          <!-- 會員 -->
          <!-- <div class="l-header__login">
            <div v-show="isLogin === true && isGetUserData" class="l-header__login--signin" @click="showMemberMenu = !showMemberMenu">
              <img v-if="!userData.avatar" src="https://static.ctwant.com/images/dist/user.svg" alt="default user img">
              <img v-else :src="userData.avatar" alt="default user img">
              <p>
                {{ userData.name }}
              </p>
              <ul v-if="showMemberMenu" class="l-header__member-menu" @mouseleave="showMemberMenu = false">
                <li>
                  <nuxt-link to="/member/" @click="showMemberMenu = false">
                    會員資料
                  </nuxt-link>
                </li>
                <li>
                  <p @click="logout">
                    登出
                  </p>
                </li>
              </ul>
            </div>
            <p v-show="isLogin === false" class="l-header__login--signup" @click="login">
              登入/註冊
            </p>
            <img v-show="isLogin === null" class="l-header__loading" src="https://static.ctwant.com/images/dist/loading.gif?20200410" alt="loading">
          </div> -->
          <!-- 會員 -->
        </div>
      </div>
      <nav class="m-navbar l-header__nav">
        <div class="accesskey-top">
          <a id="accesskey-u" accesskey="U" href="#accesskey-u" title="上方主選單區塊">:::</a>
        </div>
        <ul ref="nav" class="container m-navbar__list" @touchmove="hideSubnav">
          <template v-for="category in categoryData">
            <li v-if="category.name === '最新'" :key="category.name" class="m-navbar__list__item">
              <button v-if="$route.name !== 'realtime'" class="m-navbar__list__link" title="前往最新頁" @click="goRealtime">
                {{ category.name }}
              </button>
              <h1 v-else-if="$route.name === 'realtime'" :key="category.name" class="m-navbar__list__item__h1">
                {{ category.name }}
              </h1>
              <div class="m-navbar__arrow" />
            </li>
            <li
              v-else-if="category.name === '影音' || category.name === '專題'"
              :key="category.name + '1'"
              class="m-navbar__list__item"
            >
              <nuxt-link
                :to="`${category.path}/`"
                :title="`前往${category.name}頁`"
                class="m-navbar__list__link"
                @focus.native="focusOutline($event, category.name)"
              >
                {{ category.name }}
              </nuxt-link>
              <div class="m-navbar__arrow" />
            </li>
            <li v-else :key="category.name + '2'" class="m-navbar__list__item" @mouseover="getPosition" @click.self="showSubnav">
              {{ category.name }}
              <div :style="arrowPos" :class="{ 'm-navbar__arrow--hide': subnavHide }" class="m-navbar__arrow" @mouseover="stopBubbling" />
              <div
                v-if="category.name === '永續'"
                ref="markNew"
                class="mark-new"
              />
              <ul
                :style="subnavPos"
                :class="{'m-navbar__subnav--hide': subnavHide, 'm-navbar__subnav--outline': category.isInOutline}"
                class="m-navbar__subnav"
                @click="hideSubnav"
                @mouseover="stopBubbling"
              >
                <li v-for="(child, index) in category.child" :key="child.name">
                  <nuxt-link
                    :to="`${child.path}/`"
                    :title="`前往${child.name}${index !== 0 ? '頁' : ''}`"
                    class="m-navbar__subnav__item"
                    @focus.native="focusOutline($event, category.name)"
                    @blur.native="stopBubbling"
                  >
                    <span class="m-navbar__subnav-link-span">
                      {{ child.name }}
                    </span>
                  </nuxt-link>
                </li>
              </ul>
            </li>
          </template>
        </ul>
        <button class="m-navbar__control" title="往後查看類別項目" aria-label="往後查看類別項目" @touchstart="moveNav">
          <svg width="8" height="15">
            <title>往後查看類別項目</title>
            <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill="#fff" fill-rule="nonzero" />
          </svg>
        </button>
      </nav>
      <div v-if="showPopup" class="m-login-popup">
        <div class="m-login-popup__wrapper">
          <div class="m-login-popup__head">
            <img src="https://static.ctwant.com/images/dist/xx-black.svg" alt="關閉登入畫面的符號" @click="popupClose">
          </div>
          <div class="m-login-popup__body">
            <div class="m-login-popup__img">
              <img src="https://static.ctwant.com/images/dist/login_vision.png" alt="登入畫面的美化圖">
            </div>
            <div class="m-login-popup__content">
              <div class="m-login-popup__text">
                <p>加入 CTWANT ，享受好內容。</p>
              </div>
              <div class="m-login-popup__btn-wrapper">
                <div v-for="(item, index) in loginPlatform" :key="index" :class="`m-login-popup__btn-${item}`" class="m-login-popup__btn" @click="login(item)">
                  <p>使用 {{ item }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="m-login-popup__footer">
            <input id="checkPrivacy" v-model="privacy" type="checkbox">
            <label for="checkPrivacy">
              我同意由 CTWANT 使用個資，並已詳讀
              <nuxt-link to="/privacy/" title="跳至隱私權聲明頁">
                隱私權聲明
              </nuxt-link>
            </label>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* global firebase */
import { mapState, mapGetters, mapMutations } from 'vuex'
import adTabIndexRemove from '@/utils/adTabIndexRemove.js'
export default {
  mixins: [adTabIndexRemove],
  data() {
    return {
      categoryData: [{
        name: '最新'
      }, {
        name: '社會',
        isInOutline: false,
        child: [{
          name: '社會首頁',
          path: '/category/社會'
        }, {
          name: '時事',
          path: '/category/社會/時事'
        }, {
          name: '直擊',
          path: '/category/社會/直擊'
        }, {
          name: '調查',
          path: '/category/社會/調查'
        }]
      }, {
        name: '娛樂',
        isInOutline: false,
        child: [{
          name: '娛樂首頁',
          path: '/category/娛樂'
        }, {
          name: '即時',
          path: '/category/娛樂/即時'
        }, {
          name: '大條',
          path: '/category/娛樂/大條'
        }, {
          name: '專題',
          path: '/category/娛樂/專題'
        }, {
          name: '人物',
          path: '/category/娛樂/人物'
        }]
      }, {
        name: '財經',
        isInOutline: false,
        child: [{
          name: '財經首頁',
          path: '/category/財經'
        }, {
          name: '熱線',
          path: '/category/財經/熱線'
        }, {
          name: '人物',
          path: '/category/財經/人物'
        }, {
          name: '３Ｃ',
          path: '/category/財經/３Ｃ'
        }, {
          name: '汽車',
          path: '/category/財經/汽車'
        }, {
          name: '專欄',
          path: '/category/財經/專欄'
        }]
      }, {
        name: '政治',
        isInOutline: false,
        child: [{
          name: '政治首頁',
          path: '/category/政治'
        }, {
          name: '焦點',
          path: '/category/政治/焦點'
        }, {
          name: '人物',
          path: '/category/政治/人物'
        }, {
          name: '評論',
          path: '/category/政治/評論'
        }]
      }, {
        name: '漂亮',
        isInOutline: false,
        child: [{
          name: '漂亮首頁',
          path: '/category/漂亮'
        }, {
          name: '美容',
          path: '/category/漂亮/美容'
        }, {
          name: '時尚',
          path: '/category/漂亮/時尚'
        }, {
          name: '精品',
          path: '/category/漂亮/精品'
        }]
      }, {
        name: '生活',
        isInOutline: false,
        child: [{
          name: '生活首頁',
          path: '/category/生活'
        }, {
          name: '綜合',
          path: '/category/生活/綜合'
        }, {
          name: '美旅',
          path: '/category/生活/美旅'
        }, {
          name: '健康',
          path: '/category/生活/健康'
        }, {
          name: '寵物',
          path: '/category/生活/寵物'
        }, {
          name: '運勢',
          path: '/category/生活/運勢'
        }, {
          name: '運動',
          path: '/category/生活/運動'
        }]
      }, {
        name: '國際',
        isInOutline: false,
        child: [{
          name: '國際首頁',
          path: '/category/國際'
        }, {
          name: '最新',
          path: '/category/國際/最新'
        }, {
          name: '大陸',
          path: '/category/國際/大陸'
        }]
      }, {
        name: '永續',
        isInOutline: false,
        child: [{
          name: '論壇',
          path: '/topic/42'
        }, {
          name: '新聞',
          path: '/topic/43'
        }, {
          name: '影音',
          path: '/topic/44'
        }]
      }, {
        name: '影音',
        path: '/video'
      }, {
        name: '專題',
        path: '/topic'
      }],
      subnavPos: {
        top: 0,
        left: '-120px'
      },
      arrowPos: {
        left: 'auto'
      },
      headerHeight: 0,
      headerFixed: false,
      headerShow: false,
      lastScrollTop: 0,
      subnavHide: false,
      showMemberMenu: false,
      showPopup: false,
      loginPlatform: ['Facebook', 'Google', 'Yahoo'],
      privacy: false,
      isLoading: true
    }
  },
  computed: {
    ...mapState([
      'redirectUrl'
    ]),
    ...mapState('member', [
      'isLogin',
      'isGetUserData',
      'userData'
    ]),
    ...mapGetters([
      'WIDTH_PAD',
      'screenWidth',
      'scrollTop',
      'isMobile'
    ]),
    redirectUrl() {
      return this.$store.state.redirectUrl
    }
  },
  watch: {
    scrollTop() {
      // 因為 nextTick 也抓不到載完 header 載入廣告後的高度, 所以改到滾動時動態抓
      this.headerHeight = this.$refs.header.offsetHeight

      if (this.scrollTop > this.headerHeight) {
        this.headerFixed = true
      } else {
        this.headerFixed = false
      }

      // 判斷是往上滑或往下滑, 如果最後滾動的位置大於移動的位置就是往上滾
      if (this.headerFixed && this.lastScrollTop > this.scrollTop) {
        this.headerShow = true
      } else {
        this.headerShow = false
        if (this.screenWidth <= this.WIDTH_PAD) {
          this.subnavHide = true
        }
      }

      // 設定最後滾動的位置
      if (this.scrollTop <= 0) {
        this.lastScrollTop = 0
      } else {
        this.lastScrollTop = this.scrollTop
      }
    }
  },
  mounted() {
    this.firebaseInit()

    this.$nextTick(() => {
      if (this.isMobile === false) {
        this.$_adTabIndexRemoveFn([
          this.$refs['div-gpt-ad-1563334121951-0']
        ])
      }
    })
  },
  methods: {
    ...mapMutations([
      'SET_IS_OPEN_DOWNLOAD_APP'
    ]),
    firebaseInit() {
      const config = {
        apiKey: 'AIzaSyCNgWUsrKhC2AdolwH5AbwaLMsE_EGMqBg',
        authDomain: 'ctwant-website.firebaseapp.com',
        databaseURL: 'https://ctwant-website.firebaseio.com',
        projectId: 'ctwant-website',
        storageBucket: 'ctwant-website.appspot.com',
        messagingSenderId: '35365013381',
        appId: '1:35365013381:web:6160b320db01a9ac67a6ec',
        measurementId: 'G-PGCW1H1S3E'
      }
      if (!firebase.apps.length) {
        firebase.initializeApp(config)
      }
    },
    getPosition(e) {
      const pos = e.target.getBoundingClientRect()
      const arrowWidth = 26
      const subNavOffset = 13

      this.subnavPos.top = `${pos.height}px`
      if (this.screenWidth <= this.WIDTH_PAD) {
        this.subnavPos.left = `${pos.left - subNavOffset}px`
        this.arrowPos.left = `${pos.left + (pos.width / 2) - (arrowWidth / 2)}px`
      } else {
        this.subnavPos.left = '50%'
      }
    },
    hideSubnav() {
      if (this.screenWidth <= this.WIDTH_PAD) {
        this.subnavHide = true
      }
    },
    showSubnav() {
      if (this.screenWidth <= this.WIDTH_PAD) {
        this.subnavHide = false
      }
    },
    focusOutline(e, categoryName) {
      // console.log('focusOutline categoryName: ', categoryName)
      // console.log('focusOutline e: ', e.target.getBoundingClientRect())
      if (this.subnavPos.top === 0) {
        const pos = e.target.getBoundingClientRect()
        this.subnavPos.top = `${pos.height + 10}px`
        this.subnavPos.left = '50%'
      }
      this.categoryData.forEach((category) => {
        if (category.name === categoryName) {
          category.isInOutline = true
        } else {
          category.isInOutline = false
        }
      })
    },
    blurOutline() {
      this.categoryData.forEach((category) => {
        category.isInOutline = false
      })
    },
    stopBubbling(e) {
      e.stopPropagation()
      this.categoryData.forEach((category) => {
        category.isInOutline = false
      })
    },
    moveNav() {
      const nav = this.$refs.nav
      nav.scrollLeft += 81
    },
    goRealtime() {
      if (this.$route.name === 'realtime') {
        if (this.$route.query.page) {
          this.$router.replace({ query: {} })
          return
        }
        this.$router.go(0)
      } else {
        this.$router.push(encodeURI('/category/最新'))
      }
    },
    popupClose() {
      this.showPopup = false
    },
    login() {
      this.$store.dispatch('getRedirectUrl', this.$route.path)
      location.href = this.redirectUrl
    },
    logout() {
      const routePath = this.$route.path

      this.$store.dispatch('member/logout')
      if (routePath === '/member') {
        // 在會員中心登出的話就導回首頁
        this.$store.dispatch('getRedirectUrl', '/')
      } else {
        this.$store.dispatch('getRedirectUrl', routePath)
      }
      location.href = `${this.redirectUrl}&logout=Y`
    },
    downloadApp() {
      const user = navigator.userAgent
      const isAndroid = user.includes('Android') || user.includes('Adr')
      const isIos = !!user.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid === true && isIos === false) {
        window.open('https://play.google.com/store/apps/details?id=com.ctwant.ctwantapp')
      } else if (isAndroid === false && isIos === true) {
        window.open('https://apps.apple.com/tw/app/ctwant-%E5%91%A8%E5%88%8A%E7%8E%8B/id1580357215')
      } else {
        this.SET_IS_OPEN_DOWNLOAD_APP(true)
      }
    }
  }
}
</script>
