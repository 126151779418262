<template>
  <div class="p-error container">
    <div class="p-error__content">
      <div class="p-error__text">
        <h2>
          <img src="https://static.ctwant.com/images/dist/404-font.svg" alt="404 標示">
        </h2>
        <h3>
          您所查看的頁面無法瀏覽或已經不存在
        </h3>
        <p>
          Error code: 404
        </p>
        <h4>
          您可以試試：
        </h4>
        <ul>
          <li>
            搜尋其他關鍵字
          </li>
          <li>
            造訪其他相關頁面
          </li>
        </ul>
      </div>
      <div class="p-error__img">
        <img src="https://static.ctwant.com/images/dist/404.svg" alt="404頁面美術圖示">
      </div>
    </div>
    <div class="m-searchbar p-error__searchbar">
      <div class="m-searchbar__input-wrapper">
        <label for="search_keyword">搜尋</label>
        <input id="search_keyword" v-model="q" type="text" placeholder="請輸入關鍵字" @keyup.enter="goSearch">
        <button v-show="q !== ''" class="m-searchbar__clear" title="清除關鍵字" @click="q = ''">
          ⊗ 清除
        </button>
      </div>
      <button class="m-searchbar__btn" title="使用關鍵字並前往搜尋頁進行搜尋" aria-label="使用關鍵字並前往搜尋頁進行搜尋" @click="goSearch">
        <title>使用關鍵字並前往搜尋頁進行搜尋</title>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g id="首頁" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="首頁＿web(1440)" transform="translate(-1325.000000, -274.000000)" fill="#ffffff" fill-rule="nonzero">
              <g id="search-(1)" transform="translate(1325.000000, 274.000000)">
                <path id="Shape" d="M23.6350957,21.8601973 L17.7186852,15.9193905 C19.2398983,14.173507 20.0733799,11.9768283 20.0733799,9.68999052 C20.0733799,4.34701401 15.5708335,0 10.03669,0 C4.50254639,0 0,4.34701401 0,9.68999052 C0,15.032967 4.50254639,19.379981 10.03669,19.379981 C12.1142848,19.379981 14.094131,18.7749886 15.7868406,17.6265141 L21.748198,23.6124004 C21.9973698,23.8622336 22.3325079,24 22.6916469,24 C23.0315852,24 23.3540684,23.8748727 23.5988764,23.6473686 C24.1190387,23.164133 24.1356211,22.3628129 23.6350957,21.8601973 Z M10.03669,2.52782361 C14.1272957,2.52782361 17.455113,5.74068743 17.455113,9.68999052 C17.455113,13.6392936 14.1272957,16.8521574 10.03669,16.8521574 C5.94608424,16.8521574 2.61826695,13.6392936 2.61826695,9.68999052 C2.61826695,5.74068743 5.94608424,2.52782361 10.03669,2.52782361 Z" />
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="l-section">
      <h2 class="l-section__title-with-hr">
        猜你喜歡
      </h2>
      <hr>
      <client-only>
        <div v-if="datas !== null" class="l-section__content">
          <div class="row">
            <nuxt-link v-for="i in articleShower(datas.articles.items, 4, 3, 2)" :key="i.article_id" :to="`/article/${i.article_id}/`" title="前往新聞頁" class="m-card col-lg-3 col-md-4 col-6">
              <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                <span :class="articleType(i).style" class="e-type">
                  {{ articleType(i).text }}
                </span>
              </div>
              <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" />
              <h3 class="m-card__title m-card--mle-2">
                {{ i.title }}
              </h3>
            </nuxt-link>
          </div>
        </div>
      </client-only>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import articleHelpers from '@/utils/articleHelpers.js'
import LazyImage from '@/components/LazyImage.vue'
export default {
  components: {
    LazyImage
  },
  mixins: [articleHelpers],
  data() {
    return {
      datas: null,
      q: ''
    }
  },
  computed: {
    ...mapGetters([
      'WIDTH_PAD',
      'WIDTH_MOBILE',
      'screenWidth'
    ]),
    articleShower() {
      const vm = this
      const tableWidth = this.WIDTH_PAD
      const mobileWidth = this.WIDTH_MOBILE

      return function (data, pc, table = pc, mobile = pc) {
        if (vm.screenWidth > tableWidth) {
          return data.slice(0, pc)
        } else if (vm.screenWidth > mobileWidth) {
          return data.slice(0, table)
        } else {
          return data.slice(0, mobile)
        }
      }
    },
    selectImageSize() {
      const sw = this.screenWidth
      return function (imageArr) {
        let imgUrl = null

        if (sw <= this.WIDTH_PAD) {
          imgUrl = imageArr.sm
        } else {
          imgUrl = imageArr.md
        }
        return imgUrl
      }
    }
  },
  created() {
    this.getData()
  },
  beforeMount() {
    this.SET_IS_POPUP_ACTIVE(false)
  },
  mounted() {
  },
  methods: {
    ...mapMutations('ad', [
      'SET_IS_POPUP_ACTIVE'
    ]),
    async getData() {
      const api = this.$axios.defaults.baseURL

      try {
        const data = await this.$axios.$get(encodeURI(`${api}/category/最新`))
        this.datas = data.data
      } catch (e) {
        console.error('Error getData', {
          code: e.response.data.code,
          message: e.response.data.message,
          method: e.response.config.method,
          url: e.response.config.url
        })
      }
    },
    goSearch() {
      if (this.q === '') {
        this.$notyf.error('請輸入搜尋關鍵字')
        return
      }
      if (this.q.length >= 20) {
        this.$notyf.error('搜尋關鍵字請少於 20 個字')
        return
      }
      this.q = this.q.trim()
      const obj = {
        path: `/search/${this.q.trim()}/`
      }
      this.$router.push(obj)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'notyf/notyf.min.css';
  @import '~/assets/scss/pages/_error';
</style>
