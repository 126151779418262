export const state = () => ({
  adSlots: [],
  realtimeAdSlots: [],
  popupAdSlot: null,
  // interstitialAdSlot: null,
  interstitialCloseNextTo: '',
  isHideAd: 'N',
  isOtherAd: 'N',
  isPopupActive: true,
  isAlreadyRenderPopupAd: false,
  disabledAd: false,
  articlePrevOrNextCount: 0,
  isInterstitialAdSlotOnLoad: false
})

export const actions = {
  disableAd({ state, commit }) {
    commit('SET_AD_DISABLED', true)
  },
  registerGlobalSlots({ state, rootState, commit }) {
    window.googletag = window.googletag || { cmd: [] }
    googletag.cmd.push(function () {
      const allTopSizeMapping = googletag.sizeMapping().addSize([0, 0], [[300, 250], [1, 1]]).addSize([728, 0], [[728, 90], [1, 1]]).addSize([970, 0], [[970, 250], [1, 1]]).build()
      const slotList = [
        googletag.defineSlot('/21830015939/CTWant_all_L2_728x90_320x100_RWD', [[320, 100], [728, 90]], 'div-gpt-ad-1562662964306-0').addService(googletag.pubads()),
        // googletag.defineSlot('/21830015939/CTWant_all_L2_728x90_320x100_RWD', [[320, 100], [728, 90], [1, 1]], 'div-gpt-ad-1562662964306-0').addService(googletag.pubads()),
        googletag.defineSlot('/21830015939/CTWant_home_L1_970x90', [970, 90], 'div-gpt-ad-1562665396118-0').addService(googletag.pubads()),
        // 內文頁 - 封面圖
        // googletag.defineSlot('/21830015939/ctwant_datawarkz_inimage', [1, 1], 'div-gpt-ad-1620032974174-0').addService(googletag.pubads()),
        // 內文頁 - 第二段 - 左
        // googletag.defineSlot('/21830015939/CTWant_article_300x250', [[1, 1], [300, 250]], 'div-gpt-ad-1562665659288-0').addService(googletag.pubads()),
        googletag.defineSlot('/21830015939/CTWant_article_300x250', [[300, 250]], 'div-gpt-ad-1562665659288-0').addService(googletag.pubads()),
        // googletag.defineSlot('/21830015939/CTWant_Pushdown_300x250', [300, 250], 'div-gpt-ad-1563877821391-0').addService(googletag.pubads()),
        // googletag.defineSlot('/21830015939/CTWant_Turntable', [1, 1], 'div-gpt-ad-1563877894783-0').addService(googletag.pubads()),
        // 內文頁 - 第二段 - 右
        // googletag.defineSlot('/21830015939/CTWant_article_left_300x250', [[1, 1], [300, 250], [300, 600]], 'div-gpt-ad-1566899414172-0').addService(googletag.pubads()),
        googletag.defineSlot('/21830015939/CTWant_article_left_300x250', [[300, 250]], 'div-gpt-ad-1566899414172-0').addService(googletag.pubads()),
        // 內文頁 - 第四段
        googletag.defineSlot('/21830015939/article_p4', [[300, 250]], 'div-gpt-ad-1579516679912-0').addService(googletag.pubads()),
        // googletag.defineSlot('/21830015939/article_p4', [[1, 1], [300, 250], [320, 480], [640, 360]], 'div-gpt-ad-1579516679912-0').addService(googletag.pubads()),
        // 內文頁 - 第八段
        // googletag.defineSlot('/21830015939/fixbottom', [1, 1], 'div-gpt-ad-1580803837464-0').addService(googletag.pubads()),
        // 內文頁 - 延伸閱讀
        googletag.defineSlot('/21830015939/article_extend_middle', ['fluid'], 'div-gpt-ad-1634030369427-0').addService(googletag.pubads()),
        // 內文頁 - 延伸閱讀 - 最後
        googletag.defineSlot('/21830015939/article_extended_last', ['fluid'], 'div-gpt-ad-1584587150345-0').addService(googletag.pubads()),
        // 文末
        // googletag.defineSlot('/21830015939/article_end', [[1, 1], [300, 250], [320, 100], [728, 90]], 'div-gpt-ad-1590467132070-0').addService(googletag.pubads()),
        googletag.defineSlot('/21830015939/article_end', [[1, 1], [300, 250], [320, 100], [728, 90]], 'div-gpt-ad-1590467132070-0').addService(googletag.pubads()),
        // 首頁輪播
        googletag.defineSlot('/21830015939/index_carousel', ['fluid'], 'div-gpt-ad-1594969509750-0').addService(googletag.pubads()),
        // 人氣新聞 - 第六則
        googletag.defineSlot('/21830015939/side_bar-6', ['fluid'], 'div-gpt-ad-1634112351745-0').addService(googletag.pubads())
      ]

      // div-gpt-ad-1617869450309-0 沒有直售廣告的話會先關閉，所以暫時不需要此 if
      // 手機版側邊欄廣告 (人氣新聞的上下)
      if (rootState.screenWidth > rootState.WIDTH_PAD) {
        slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x600', [300, 600], 'div-gpt-ad-1562662720262-0').setCollapseEmptyDiv(true).addService(googletag.pubads()))
        slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x250_1', [300, 250], 'div-gpt-ad-1562662516421-0').addService(googletag.pubads()))
        slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x250_2', [300, 250], 'div-gpt-ad-1562662605891-0').addService(googletag.pubads()))
      } else {
        slotList.push(googletag.defineSlot('/21830015939/scupio_CTWant_all_300x250_1_MB', [300, 250], 'div-gpt-ad-1617869450309-0').addService(googletag.pubads()))
      }
      /*
      slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x600', [300, 600], 'div-gpt-ad-1562662720262-0').setCollapseEmptyDiv(true).addService(googletag.pubads()))
      slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x250_1', [300, 250], 'div-gpt-ad-1562662516421-0').addService(googletag.pubads()))
      slotList.push(googletag.defineSlot('/21830015939/CTWant_all_300x250_2', [300, 250], 'div-gpt-ad-1562662605891-0').addService(googletag.pubads()))
      */

      // 置頂
      if (rootState.screenWidth > rootState.WIDTH_PAD) { // Only PC
        slotList.push(googletag.defineSlot('/21830015939/CTWant_all_top_970x250_300x250_RWD', [[728, 90], [970, 250]], 'div-gpt-ad-1563334121951-0').defineSizeMapping(allTopSizeMapping).setCollapseEmptyDiv(true, true).addService(googletag.pubads()))
      }

      // 置底
      if (rootState.screenWidth < rootState.WIDTH_PAD) { // Only Mobile
        slotList.push(googletag.defineSlot('/21830015939/CTWant_fixbottom_320x50', [[1, 1], [320, 50]], 'div-gpt-ad-1566899685984-0').addService(googletag.pubads()))
      }

      // 註冊插頁式廣告
      // const interstitialAdSlot = googletag.defineOutOfPageSlot('/7682122,21830015939/SF_MW_CTWant_all_Interstitials', googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads())

      googletag.pubads().enableSingleRequest()
      googletag.pubads().collapseEmptyDivs(true)
      /**
       * 有使用 disableInitialLoad 的話就不能用 googletag.display
       * https://developers.google.com/publisher-tag/guides/control-ad-loading#control_ad_loading
       */
      googletag.pubads().disableInitialLoad()
      if (!state.disabledAd) {
        googletag.enableServices()
      }
      commit('SET_AD_SLOTS', [...slotList])
      // commit('SET_INTERSTITIAL_AD_SLOT', interstitialAdSlot)

      /*
      // 插頁廣告是否已經準備好被渲染至 iframe 監聽器 (此時還未渲染，只是準備好了)
      // https://developers.google.com/publisher-tag/reference?hl=zh-tw#googletag.events.slotonloadevent
      googletag.pubads().addEventListener('slotOnload', (event) => {
        // console.log('Creative iframe for slot', event.slot.getSlotElementId(), 'has loaded.')
        if (state.interstitialAdSlot === event.slot) {
          commit('SET_IS_INTERSTITIAL_AD_SLOT_ON_LOAD', true)
        }
      })
      */
    })
  },
  registerAndDisplayAutoRefreshSlot() {
    googletag.cmd.push(function () {
      const slot = googletag.defineSlot('/21830015939/ctwant_andbeyond', [1, 1], 'div-gpt-ad-1610439124541-0').addService(googletag.pubads())
      googletag.pubads().refresh([slot])
    })
  },
  displayAd({ state, commit }, { isHideAd, isOtherAd } = { isHideAd: 'N', isOtherAd: 'N' }) {
    commit('SET_IS_HIDE_AD', isHideAd)
    if (isOtherAd === 'Y') {
      googletag.cmd.push(function () {
        googletag.pubads().setTargeting('adult', 'Y')
      })
    } else if (isOtherAd === 'N' && state.isOtherAd === 'Y') {
      googletag.cmd.push(function () {
        googletag.pubads().clearTargeting()
      })
    }
    commit('SET_IS_OTHER_AD', isOtherAd)
    // 載入廣吿
    googletag.cmd.push(function () {
      googletag.pubads().refresh([...state.adSlots, ...state.realtimeAdSlots])
    })
  },
  registerAndDisplayPopupAd({ state, commit, rootState }, { type = '其他', isHideAd = 'N' } = {}) {
    const POPUP_AD_DOM_ID = 'ad-popup-1582014334406-0'
    const slotNames = {
      娛樂: '/21830015939/CTWant_popup_entertainment_320x480',
      漂亮: '/21830015939/CTWant_popup_fashion_320x480',
      財經: '/21830015939/CTWant_popup_Finance_320x480',
      其他: '/21830015939/CTWant_popup_320x480'
    }
    googletag.cmd.push(function () {
      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        if (event.slot.getSlotElementId() === POPUP_AD_DOM_ID && event.isEmpty) {
          commit('SET_IS_POPUP_ACTIVE', false)
        }
      })
    })
    if (isHideAd === 'Y') {
      commit('SET_IS_POPUP_ACTIVE', false)
      return
    }

    // 阻擋已渲染過 或是 PC版，直接return 不產生 (Popup AD)
    if (!state.isPopupActive || rootState.screenWidth > rootState.WIDTH_MOBILE) {
      return
    }
    googletag.cmd.push(function () {
      // 避免重複 defineSlot
      if (state.popupAdSlot === null && !state.isAlreadyRenderPopupAd) {
        commit('SET_POPUP_AD_SLOTS', googletag.defineSlot(Object.prototype.hasOwnProperty.call(slotNames, type) ? slotNames[type] : slotNames['其他'], [[1, 1], [300, 250], [320, 480]], POPUP_AD_DOM_ID).addService(googletag.pubads()))
        commit('SET_IS_ALREADY_RENDER_POPUP_AD', true)
        googletag.pubads().refresh([state.popupAdSlot])
      }
    })
  }
  /*
  displayInterstitialAd({ state, commit }) {
    googletag.cmd.push(() => {
      googletag.pubads().refresh([state.interstitialAdSlot]) // 將插頁式廣告觸發。此時插入 iframe 大概會需要快1秒
      const aTag = document.createElement('a') // 插頁式廣告本身觸發後就會瀏覽器重整，所以用 <a> 點擊切換頁比較穩定
      aTag.setAttribute('href', state.interstitialCloseNextTo)
      aTag.setAttribute('style', 'position:absolute;z-index:-1;')
      document.body.appendChild(aTag)
      setTimeout(() => { // 等待上述的約1秒，讓 slotOnload 監聽查看是否成功載入插頁廣告（部分瀏覽器會阻擋插頁廣告）
        if (state.isInterstitialAdSlotOnLoad === false) { // 載入失敗（可能是被阻擋），使用 nuxt.router 進行換頁
          localStorage.setItem('__lsv__', `[${new Date().getTime()}]`) // 手動加入一組在這一小時內不會再被觸發的插頁廣告值，避免每次看三頁都會 lag 1秒問題
          this.$router.push({ path: state.interstitialCloseNextTo })
        } else { // 成功載入，自動點擊該 click 進行換頁（中間過程會被插入廣告）。不需要 interstitialCloseNextTo 切換 false, 由於如果成功載入廣告後頁面會 reload
          aTag.click()
        }
      }, 1000)
    })
  }
  */
}

export const mutations = {
  SET_AD_SLOTS(state, adSlots) {
    state.adSlots = [...adSlots]
  },
  SET_REALTIME_AD_SLOTS(state, adSlots) {
    state.realtimeAdSlots = adSlots
  },
  SET_POPUP_AD_SLOTS(state, adSlot) {
    state.popupAdSlot = adSlot
  },
  // SET_INTERSTITIAL_AD_SLOT(state, adSlot) {
  //   state.interstitialAdSlot = adSlot
  // },
  SET_INTERSTITIAL_CLOSE_NEXT_TO(state, path) {
    state.interstitialCloseNextTo = path
  },
  SET_IS_HIDE_AD(state, status) {
    state.isHideAd = status
  },
  SET_IS_OTHER_AD(state, status) {
    state.isOtherAd = status
  },
  SET_IS_POPUP_ACTIVE(state, status) {
    state.isPopupActive = status
  },
  SET_IS_ALREADY_RENDER_POPUP_AD(state, status) {
    state.isAlreadyRenderPopupAd = status
  },
  SET_AD_DISABLED(state, status) {
    state.disabledAd = status
  },
  SET_ARTICLE_PREV_OR_NEXT_COUNT(state, num) {
    state.articlePrevOrNextCount = num
  },
  SET_IS_INTERSTITIAL_AD_SLOT_ON_LOAD(state, status) {
    state.isInterstitialAdSlotOnLoad = status
  }
}

export const getters = {
  interstitialCloseNextTo: state => state.interstitialCloseNextTo,
  isPopupActive: state => state.isPopupActive,
  articlePrevOrNextCount: state => state.articlePrevOrNextCount
}
