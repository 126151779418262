<template>
  <div
    v-if="isShowLive"
    ref="stream"
    :class="{'m-stream--vertical': isVertical}"
    class="m-stream"
  >
    <div class="m-stream__title">
      <a
        :id="type === 'live17' ? 'live17-Stream' : 'FB-Stream'"
        :href="showUrl"
        title="另開視窗至 17 live 的直播間"
        target="_blank"
        rel="noreferrer noopener nofollow"
      >
        {{ showTitle !== '' ? showTitle : '點我看直播' }}
      </a>
      <button type="button" class="m-stream__title-close" title="關閉直播" @click="liveClose">
        <img src="https://static.ctwant.com/images/dist/xx.svg" alt="關閉直播圖示">
      </button>
    </div>
    <div ref="live" class="m-stream__live" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Stream',
  data() {
    return {
      closeLiveTimer: null,
      carouselLiveTimer: null,
      carouselActive: +Cookies.get('carouselActive') || 0,
      isShowLive: false,
      isVertical: false, // 直播是否為直式
      showTitle: '',
      showUrl: '',
      type: '',
      mode: 'timing',
      start_at_ts: '',
      end_at_ts: '',
      stream_items: []
    }
  },
  async mounted() {
    const api = this.$axios.defaults.baseURL
    const url = `${api}/stream`
    try {
      const { data } = await this.$axios.$get(url)
      if (Object.keys(data).length > 0 && data.stream_items.length > 0) {
        data.isShowLive = true
        Object.keys(data).forEach(key => (this[key] = data[key]))

        this.$nextTick(() => {
          const { live } = this.$refs
          const nowTimestamp = Math.round(Date.now() / 1000)
          const overCloseTimestamp = this.end_at_ts - (nowTimestamp) // 什麼時候要自動關掉 (直播檔期結束時間 - 現在時間 (timestamp)) 單位:秒
          if (overCloseTimestamp > 0) {
            this.carouselActive = this.stream_items.findIndex((item, index) => {
              if (index === this.stream_items.length - 1) {
                return item
              }
              return nowTimestamp >= item.start_timestamp && nowTimestamp < this.stream_items[index + 1].start_timestamp
            })
            this.calculateVerticalOrHorizontal(live, this.stream_items[this.carouselActive])

            if (this.stream_items.length >= 2) { // 如果直播數量大於1 啟用輪播
              if (this.mode === 'timing') { // 如果是定時模式啟用此
                if (this.carouselActive > this.stream_items.length - 1) {
                  this.carouselActive = 0
                  Cookies.set('carouselActive', this.carouselActive, { expires: 1 / 48 }) // 30minutes
                }
                this.pasteLive(live)
                this.carouselLive(live)
              } else if (this.mode === 'schedule') { // 如果是排程模式啟用此
                if (nowTimestamp >= this.stream_items[this.stream_items.length - 1].start_timestamp) { // 如果現在時段已是最後一個直播的排程
                  this.carouselActive = this.stream_items.length - 1
                  this.pasteLive(live)
                } else { // 如果現在時段已"非"最後一個直播的排程
                  this.pasteLive(live)
                  this.carouselLiveWithSchedule(live)
                }
              }
            } else {
              const streamItemOne = this.stream_items[0]
              live.innerHTML = streamItemOne.content
              this.showTitle = streamItemOne.title
              this.showUrl = streamItemOne.url
            }
            this.SET_IS_PLAYING_STREAM(true)
            this.liveClose(overCloseTimestamp)
          }
        })
      }
    } catch (e) {
      console.error('Stream', {
        code: e.response.data.code,
        message: e.response.data.message,
        method: e.response.config.method,
        url: e.response.config.url
      })
    }
  },
  methods: {
    ...mapMutations([
      'SET_IS_PLAYING_STREAM'
    ]),
    calculateVerticalOrHorizontal(live, streamItem) {
      let resLiveDomWidth = 300 // default setting (寬高比16:9 (56.33%))
      let resLiveDomHeight = 169
      if (this.type === 'facebook') {
        if (streamItem.content.includes('style="') && streamItem.content.includes('width: ') && streamItem.content.includes('height: ')) {
          resLiveDomWidth = streamItem.content.split('style="')[1].split('width: ')[1].split('px')[0]
          resLiveDomHeight = streamItem.content.split('style="')[1].split('height: ')[1].split('px')[0]
        }
      } else if (this.type === 'live17') {
        if (streamItem.content.includes('<embed')) {
          resLiveDomWidth = streamItem.content.split('width="')[1].split('"')[0]
          resLiveDomHeight = streamItem.content.split('height="')[1].split('"')[0]
        }
      }
      const proportion = (resLiveDomHeight / resLiveDomWidth * 100).toFixed(2) // 算出比例 （由於default iframe size 是由fb來的，只能用該寬高比算出比例方式長出寬高）
      if (+proportion > 100) { // 寬高比% 大於100即為直式
        this.isVertical = true
      } else {
        this.isVertical = false
      }
      live.style.paddingBottom = `${proportion}%` // 使用 padding-bottom 撐比例的高度
    },
    pasteLive(liveDom) {
      liveDom.innerHTML = `
      ${this.stream_items[this.carouselActive].content}
      <div class="m-stream__live-carousel-item${this.isVertical ? ' m-stream__live-carousel-item--vertical' : ''}"></div>
      `.trim()
      this.showTitle = this.stream_items[this.carouselActive].title
      this.showUrl = this.stream_items[this.carouselActive].url
    },
    carouselLive(liveDom) {
      this.$nextTick(() => {
        const distance = liveDom.clientWidth
        this.carouselLiveTimer = setInterval(() => {
          const externalLive = liveDom.querySelector('embed') || liveDom.querySelector('iframe')
          const nextFakeCarouselItem = document.querySelector('.m-stream__live-carousel-item')
          if (this.carouselActive >= this.stream_items.length - 1) {
            this.carouselActive = 0
            Cookies.set('carouselActive', this.carouselActive, { expires: 1 / 48 })
          } else {
            this.carouselActive++
            Cookies.set('carouselActive', this.carouselActive, { expires: 1 / 48 })
          }
          this.calculateVerticalOrHorizontal(liveDom, this.stream_items[this.carouselActive])
          externalLive.style.left = `${-distance}px`
          nextFakeCarouselItem.style.left = 0
          externalLive.addEventListener('transitionend', () => {
            externalLive.style.transitionDuration = '0s'
            externalLive.style.left = 0
            this.pasteLive(liveDom)
            externalLive.style.transitionDuration = '1s'
          })
        }, 120000) // 120000 = 2min
      })
    },
    carouselLiveWithSchedule(liveDom) {
      this.$nextTick(() => {
        const distance = liveDom.clientWidth
        this.carouselLiveTimer = setInterval(() => {
          const nowTimestamp = Math.round(Date.now() / 1000)
          const currentIdx = this.stream_items.findIndex((item, index) => {
            if (index === this.stream_items.length - 1) {
              return item
            }
            return nowTimestamp >= item.start_timestamp && nowTimestamp < this.stream_items[index + 1].start_timestamp
          })
          const isSwitch = currentIdx !== this.carouselActive

          if (isSwitch) {
            this.carouselActive = currentIdx
            this.calculateVerticalOrHorizontal(liveDom, this.stream_items[this.carouselActive])
            const externalLive = liveDom.querySelector('embed') || liveDom.querySelector('iframe')
            const nextFakeCarouselItem = document.querySelector('.m-stream__live-carousel-item')
            externalLive.style.left = `${-distance}px`
            nextFakeCarouselItem.style.left = 0
            externalLive.addEventListener('transitionend', () => {
              externalLive.style.transitionDuration = '0s'
              externalLive.style.left = 0
              this.pasteLive(liveDom)
              externalLive.style.transitionDuration = '1s'
            })
          }
        }, 60000)
      })
    },
    liveClose(overTimestamp) {
      const millisecond = overTimestamp * 1000 // 轉成毫秒
      const { stream } = this.$refs
      if (overTimestamp && overTimestamp > 0) {
        this.closeLiveTimer = setTimeout(() => {
          stream.remove()
          clearInterval(this.carouselLiveTimer)
          Cookies.remove('carouselActive')
          this.SET_IS_PLAYING_STREAM(false)
        }, millisecond)
      } else {
        stream.remove()
        clearTimeout(this.closeLiveTimer)
        clearInterval(this.carouselLiveTimer)
        Cookies.remove('carouselActive')
        this.SET_IS_PLAYING_STREAM(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/modules/_stream';
</style>
