<template>
  <div :class="{ 'l-ad--hide-all-amp-units': isHideAd === 'Y' }">
    <HeaderAMP />
    <Nuxt />
    <FooterAMP />
    <amp-analytics type="comscore">
      <script type="application/json" v-html="AMP_ANALYTICS" />
    </amp-analytics>
  </div>
</template>

<script>
import HeaderAMP from '@/components/HeaderAMP.vue'
import FooterAMP from '@/components/FooterAMP.vue'

export default {
  components: {
    HeaderAMP,
    FooterAMP
  },
  data() {
    return {
      AMP_ANALYTICS: '{"vars": {"c2": "32800769"}, "extraUrlParams": {"comscorekw": "amp"}}'
    }
  },
  computed: {
    isHideAd() {
      return this.$store.state.ad.isHideAd
    }
  }
}
</script>

<style lang="scss">
// base
@import '~/assets/scss/base/_amp_reset';
</style>
